label.horizontal-required::before {
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*";
	margin-bottom: 4px;
}
.preview_table_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background: #f8f8f8;
	border: 1px solid #babfc7;
	border-bottom: 0;
	font-size: 16px;
	font-weight: 600;
	color: #181d1f;
}

.preview_table_footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background: #f8f8f8;
	border: 1px solid #babfc7;
	border-top: 0;
	font-size: 16px;
	font-weight: 600;
	color: #181d1f;
}
.preview_table_footer + .preview_table_header {
	border-top: 0;
}

.ag-theme-alpine .customHeadheight .ag-header {
    min-height: 97px !important;
    height: 97px !important;
}

.ag-theme-alpine .customHeadheight .ag-header-cell-resize::after {
	width: 1px;
    height: 100%;
    top: 0;
}

.width50 .ant-col.ant-col-12 .ant-row.ant-form-item {
    width: 50%;
}
.a_ETR_CSS .ant-page-header-content{
    height: calc(100% - 120px)
}
