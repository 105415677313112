.text_blue_color {
	color:#458ce3;
}

.text_green_color {
	color: #aad952;
}

.back_green_color {
	background-color: #e6ffed;
}

.back_red_color {
	background-color: #fdeef0;
}
