$primary_font: -apple - system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans - serif;

$primary_color: #1890ff;
$danger_color: #d9363e;
$success_color: #4caf50;
$success_dark: darken($success_color, 10%);
$danger_dark: darken($danger_color, 10%);

$loader_bg: #fff;
$site_layout_background: #fff;
$min_layout_height: 100vh;
$logo_height: 58px;
$sidebar_width: 280px;
$sidebar_height: calc(100vh - 134px);
$scroll_light_color: #e9e9e9;
$scroll_dark_color: #b5b8bb;
$scroll_hover_color: #93999e;
$ag_alter_color: #f8f8f8;

$widget_padding_horizontal: 15px;
$widget_padding_vertical: 10px;
$counter_widget_icon_font: 45px;
$counter_widget_name_font: 16px;
$counter_widget_count_font: 14px;
$counter_widget_button_font: 12px;
