.has_export_spinner {
	.ant-spin-nested-loading, .ant-spin-container {
		height: 100%;
	}
}

.query_bank{
	.ant-page-header-heading{
		.ant-page-header-heading-left{
			align-items: flex-start !important;
		}
		.ant-page-header-heading-extra{
			form{
				.ant-col.ant-form-item-label{
					label{
						padding-top: 6px
					}
				}
			}
		}
	}
}
.query_bank{
	.ant-form-item-has-error{
		margin-bottom: 0!important;
	}
}
