.no_header thead{
	display: none;
	padding: 0px;
}

.cell_scroll {
    overflow: auto;
    line-height: 20px !important;
    padding-top: 5px;
    padding-bottom: 5px;
}
