@import "antd";
@import "core";

// INCLUDE SCSS FOR INDIVIDUAL COMPONENTS

@import "widget";
@import "formulize.scss";

// INCLUDE SCSS FOR INDIVIDUAL PAGES

@import "pages/login";
@import "pages/tte_transaction_type";
@import "pages/journals";
@import "pages/tte_transaction_modal";
@import "pages/algorithm";
@import "pages/ledger_map_bank";
@import "pages/reports";
@import "pages/all_ledgers";
@import "pages/import";
@import "pages/all_transaction";
@import "pages/ledger_setting";
@import "pages/currency";
@import "pages/people_management";
@import "pages/auditlog";
@import "pages/gobal_settings";
@import "pages//user";
