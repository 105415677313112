.exchange__radio_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f0f2f5;
  padding: 10px;

  .ant-radio + span{
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.exchange_image.ant-avatar {
  background-color: white; 
  padding: 5px;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
  img {
    object-fit: contain;
  }
}