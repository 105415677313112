.ipsettingsgrid {
	[col-id="ipAddress"] {
		padding: 0;
		.ag-react-container {
			padding: 0 24px;
			line-height: 25px;
			overflow: auto;
			height: 100%;
		}
	}
}

.coverter-style {
	flex-wrap: nowrap;
	word-break: break-all;
	white-space: normal;
	line-height: 20px;
	align-items: center;
}

.has-iteration .ant-form-item-control-input-content {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

}