.fields_draggable_wrapper {
	display: flex;
	align-items: center;
	z-index: 1000;
	background-color: #fff;
	padding: 10px;
}
.fields_draggable_container {
	margin-left: -10px;
	margin-right: -10px;
}

.cardScrollable {
    max-height: 350px;
    overflow-y: auto;
}

.modalScrollable {
    max-height: 100px;
    overflow-y: auto;
    // margin-top: 10px;
}

.modalScrollable li span {
    border-bottom: 1px solid;
    padding-bottom: 2px;
}

.modalScrollable li span:hover {
    border-bottom: 1px solid transparent;
}