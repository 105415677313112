.widget_wrapper .ant-card-body {
	padding: $widget_padding_vertical $widget_padding_horizontal;
}
.counter__widget {
	display: flex;
	align-items: center;
}
.counter__widget__icon {
  margin-right: 15px;
  font-size: $counter_widget_icon_font;
}
.counter__widget__name {
	font-size: $counter_widget_name_font;
}
.counter__widget__counts {
	font-size: $counter_widget_count_font;
}
.counter__widget__buttons {
	display: flex;
	justify-content: flex-end;
	margin-left: -$widget_padding_horizontal/2;
	margin-right: -$widget_padding_horizontal/2;
}
.counter__widget__button {
	font-size: $counter_widget_button_font;
  color: #999;
  
  &.cs_active, &.active, &:hover, &:focus {
    color: #222;
  }
}