@import "skin/default";
@import "helper";

body {
	margin: 0;
	font-family: $primary_font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fullscreen__spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	background: $loader_bg;
}
.grid_wrapper,
.ant-page-header.ant-page-header-ghost {
	height: 100%;
	min-height: 400px;
	padding-left: 0;
	padding-right: 0;
}
.ant-page-header-content {
	height: calc(100% - 15px);
}
.main__layout__wrapper {
	min-height: $min_layout_height;
}
.site-layout .site-layout-background {
	background: $site_layout_background;
}
.logo {
	height: $logo_height;
	background: white;
	display: flex;
	align-items: center;
	padding: 0px 25px;
	img {
		width: 100%;
		height: auto;
	}
}
.site-header {
	display: flex;
	justify-content: space-between;
}
.ant-form-item-control-input-content {
	display: flex;
	align-items: center;
}
.ag-paging-panel.ag-unselectable {
	display: flex;
	justify-content: center;
}
.add_item_dropdown_wrapper {
	padding: 10px;
	display: flex;
	justify-content: space-between;
}

.offline_section {
	position: fixed;
	z-index: 9999;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.9);
}

.ant-modal-header .ant-modal-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-right: 20px;
}

.sider_area {
	height: 100vh;
	position: fixed;
	z-index: 10;
	width: $sidebar_width;
	ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
		height: $sidebar_height;
		overflow-y: auto;
	}
	& + section.ant-layout.site-layout {
		padding-left: $sidebar_width;
		transition: all 0.3s;
	}
}
.ant-layout-sider-collapsed {
	& + section.ant-layout.site-layout {
		padding-left: 80px;
	}
	.ant-menu-item > span,
	.ant-menu-submenu-title > span {
		display: none;
	}
}
* {
	scrollbar-color: $scroll_dark_color $scroll_light_color;
	scrollbar-width: thin;
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-track {
		background: $scroll_light_color;
	}

	::-webkit-scrollbar-thumb {
		background: $scroll_dark_color;
		transition: all 0.3s ease-in-out;
		&:hover {
			background: $scroll_hover_color;
		}
	}
}
.ant-result-404 {
	margin: auto;
}
.ant-btn {
	&:hover {
		transition: all 0.2s linear;
		.anticon-edit {
			color: $primary_color;
		}
		.anticon-delete {
			color: $danger_color;
		}
	}
}

.ag-theme-alpine .ag-cell {
	line-height: 29px !important;
	font-size: 12px;
	padding-left: 10px !important;
	padding-right: 10px !important;
}
.ag-theme-alpine .custom_style .ag-row[row-id="4"], .ag-theme-alpine .custom_style .ag-row[row-id="7"], .ag-theme-alpine .custom_style .ag-row[row-id="11"], .ag-theme-alpine .custom_style .ag-row[row-id="15"] {
	border-bottom: 1px solid #274358;
}
.ag-theme-alpine .custom_style .ag-row[row-id="19"]{
	background-color: green;
	color: #fff;
}
.ag-theme-alpine.reduce_height .ag-cell {
	line-height: 33px;
	padding-left: 10px;
	padding-right: 10px;
}

.ag-theme-alpine .ag-ltr .ag-cell:not(.ag-cell-focus) {
	border-right: 1px solid #dde2eb;
}

.action-column button {
	padding: 0px 6px;
	font-size: 16px;
	line-height: 0px;
}

.ag-theme-alpine .ag-row-odd:not(:hover) {
	background-color: $ag_alter_color;
}

.greenVerifyIcon {
	color: $success_color;
}

.redVerifyIcon {
	color: $danger_color;
}

.ant-btn.btn-success {
	background-color: $success_color;
	color: white;
	&:hover {
		background-color: $success_dark;
		color: white;
		&:disabled {
			background-color: $success_color;
		}
	}
	&:disabled {
		opacity: 0.8;
	}
}

.ant-btn.btn-danger {
	background-color: $danger_color;
	color: white;
	&:hover {
		background-color: $danger_dark;
		color: white;
		&:disabled {
			background-color: $danger_color;
		}
	}
	&:disabled {
		opacity: 0.8;
	}
}

.header_user_ledger_menu ul.ant-dropdown-menu-item-group-list {
	max-height: 50vh;
	overflow: auto;
}

.ledger_profile_photo {
	height: 40px;
	width: 40px;
	background: #ddd;
	border-radius: 50%;
	margin-right: 10px;
	background-size: cover;
	border: 2px solid $primary_color;
}

.ant-select span.ant-select-selection-item {
	white-space: pre-wrap;
}

.ant-input[disabled],
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
.ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover,
.ant-picker-input > input[disabled],
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked,
.ant-select-selection-placeholder,
.ant-checkbox-disabled + span {
	color: grey;
}

.ag-theme-alpine input[class^="ag-"]:not([type]):disabled,
.ag-theme-alpine input[class^="ag-"][type="date"]:disabled,
.ag-theme-alpine input[class^="ag-"][type="datetime-local"]:disabled,
.ag-theme-alpine input[class^="ag-"][type="number"]:disabled,
.ag-theme-alpine input[class^="ag-"][type="tel"]:disabled,
.ag-theme-alpine input[class^="ag-"][type="text"]:disabled,
.ag-theme-alpine textarea[class^="ag-"]:disabled {
	color: rgba(0,0,0,.85) !important;
	background-color: #fff !important;
	border: 1px solid #babfc7 !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item
{
	color: rgba(0,0,0,.85) !important;
}

.ant-drawer-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 20px;
}


.user__manual__wrapper {
	background-color: white;
	display: flex;
}

.user__manual__index {
	min-width: 280px;
    max-width: 280px;
	max-height: calc(100vh - 50px);
    overflow: auto;
}

.ant-layout-content .user__manual__index{
	max-height: calc(100vh - 40px);
}

.user__manual__content {
	width: 100%;
	border-left: 1px solid rgba(0,0,0,0.1);
	position: relative;
}


.index__ul {
	list-style: none;
	padding-left: 0;
	.index__li {
		font-size: 16px;
		font-weight: 600;
		line-height: 32px;
		cursor: pointer;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		&:hover, &.active {
			color: $primary_color;
		}
	}
	.index__ul {
		padding-left: 24px;
		.index__li {
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.content__ul {
	list-style: none;
	.content__li {
		margin-bottom: 24px;
	}
	.content__li__title {
		font-size: 24px;
		font-weight: 600;
	}
}

.content__text__ul {
	font-size: 16px;
	line-height: 28px;
	margin-top: 10px;

	.content__text__ul {
		margin-bottom: 10px;
		margin-top: 0px;
	}
}
.content__img__wrapper {
	padding: 24px 50px;

	.content__img__caption {
		font-size: 16px;
		margin-bottom: 10px;
	}
}
.content__img {
	max-width: 100%;
	border: 1px solid rgba(0,0,0,0.3);
}

.ant-select-selection {
	&::-webkit-input-placeholder {
	  color: grey !important;
	}
	&:placeholder {
	  color: grey !important;
	}
	:placeholder {
	  color: grey !important;
	}
	::-webkit-input-placeholder {
	  color: grey !important;
	}
}

::-webkit-input-placeholder {
color: grey !important;
}

.ant-row.ant-form-item.ant-form-item-with-help {
	margin-bottom: 24px;
}

td.ant-table-cell {
	word-break: break-all;
}

.ant-form-item-label > label .ant-form-item-tooltip {
	color: inherit;
	cursor: default;
}

.no_content_padding .ant-page-header-content {
	padding-top: 0;
}

.cs_grid .ag-react-container{
	height: 100%;
}

.w-100 > .ant-upload-list > .ant-upload {
	width: 100%;
}

.ant-form-vertical .ant-form-item-label {
    padding-bottom: 2px;
}

.ant-upload.ant-upload-drag {
    min-height: 136px;
}

.custom-table.ant-table.ant-table-bordered,
.custom-table.ant-table.ant-table-bordered >.ant-table-container,
.custom-table.ant-table.ant-table-bordered >.ant-table-container >.ant-table-content,
.custom-table.ant-table.ant-table-bordered >.ant-table-container >.ant-table-content table>tbody>tr>td,
.custom-table.ant-table.ant-table-bordered >.ant-table-container >.ant-table-content table>tbody>tr>th {
	border-color: #cecece;
}

.custom-table.ant-table.ant-table-bordered >.ant-table-container >.ant-table-content table>tbody>tr>td,
.custom-table.ant-table.ant-table-bordered >.ant-table-container >.ant-table-content table>tbody>tr>th {
	padding: 10px;
	border-right: 1px solid #cecece;
	border-bottom: 1px solid #cecece;
}

.full_height_spinner {
	height: 100%;

	.ant-spin-container {
		height: 100%;
	}
}

.default_size_textarea {
	height: 113px !important;
}


.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
	height: 28px;
}

.ant-menu-sub .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-sub .ant-menu-item .ant-menu-item-icon {
	min-width: 0.75em;
	font-size: 0.75em;
}

.ant-menu-submenu .ant-menu-sub {
	color: rgba(255, 255, 255, 0.65);
	background: #001529;
	padding: 0px 0px;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #1b2f3e;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub,.ant-layout-sider {
    background: #1b2f3e;
}

.ant-menu-root > li.ant-menu-submenu.ant-menu-submenu-inline {
	border-left: 5px solid transparent;
}

.ant-menu-root > li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected {
    border-left: 5px solid $primary_color;
	background: #233643;

	.ant-menu-sub {
		background: #233643;
	}
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: #233643;
}

.ant-menu-submenu-popup .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup li.ant-menu-item.ant-menu-item-active.ant-menu-item-selected,
.ant-menu-submenu-popup li.ant-menu-item.ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: #1b2f3e;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
	background: #233643;
}

.ant-menu-submenu-popup {
	.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
		background: #1b2f3e;
	}
}

.ant-menu-item .ant-menu-item-icon + span {
    margin-left: 0px;
}

.ant-form-item-label > label {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inherit;

	span {
	  margin-top: 3px;
	}
}

.ag-floating-filter-button {
    position: absolute;
    right: 16px;
    background: white;
}

.ag-labeled.ag-text-field.ag-input-field input{
    padding-right: 16px;
}

.link_color {
	color: #2196F3;
}

.ag-theme-alpine .ag-paging-panel {
    height: 32px;
}
.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
    padding-left: 8px!important;
    padding-right: 2px!important;
}

.ant-layout-header {
	padding: 0 24px;
}

.ant-table-content.ant-table-small.ant-table td + td {
    word-break: break-all;
}
.ant-drawer.primary .ant-drawer-extra{
	flex: 0 auto;
}

#tax_type .ant-radio-button-wrapper, #csv_columns_tax_type .ant-radio-button-wrapper{
	padding: 0 10px;
}
#csv_columns_asset_type .ant-radio-button-wrapper, #asset_type .ant-radio-button-wrapper{
	padding: 0 12px;
}
.remove-error-text .ant-form-item-explain-error, .remove-error-text .ant-form-item-explain-connected{
	display: none!important;
}
.a_ETR_CSS .ant-radio-button-wrapper{padding: 0 9px;}
#generate_on .ant-radio-button-wrapper{padding: 0 12px}

.no_data_height .ag-overlay {height: 33px;left: 0;pointer-events: none;position: absolute;top: 34px;width: 100%;}
.query_bank .ant-form-inline .ant-form-item{margin-right: 13px;}
.query_bank #generate_on .ant-radio-button-wrapper{padding: 0 8px}
.ag-theme-alpine .ag-cell-inline-editing{height: 30px !important;}
.ag-top-data .ag-header-row {overflow:visible;}
.ag-top-data .border:after{content: '';top: 0;position: absolute;width: 1px;height: 100%;background: black;right: 0;}
.reduce-space .ant-drawer-header{padding-bottom: 5px;}
.reduce-space .ant-row.ant-form-item {margin-bottom: 0px;}
.reduce-space .ant-drawer-close {margin-top: 29px;margin-right: 15px;padding: 0px;}
.reduce-space .ant-drawer-body{padding: 8px;}


.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{color: #fff!important}
.fx_gain_loss_data_header .ag-header-row {
	overflow: visible;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
	background: transparent !important;
	color: rgba(0, 0, 0, 0.25);

	&.ant-picker-cell-week {
		color: rgba(0, 0, 0, 0.25);
	}

	.ant-picker-cell-inner {
		color: rgba(0, 0, 0, 0.85);
	}

	&.ant-picker-cell-selected,
	&.ant-picker-cell-disabled {
		.ant-picker-cell-inner {
			color: #fff !important;
		}

		&:before {
			background: #274358;
		}

	}

	&:hover,
	&:foucs {
		background: transparent !important;
		color: rgba(0, 0, 0, 0.25);

		.ant-picker-cell-inner {
			color: rgba(0, 0, 0, 0.85);
		}
	}
}

.ant-picker-cell-selected {
	.ant-picker-cell-inner {
		color: #fff !important;
	}

	&:before {
		background: #274358;
	}
}

.permission-drawer .ant-card-body{
	padding: 12px;
}
.fx-gain-loss .ag-cell-label-container span.ag-header-cell-text {
	width: 8ch;
	text-overflow: clip;
}

.ag_table_grid_header .ag-header-cell-label {
   justify-content: center;
}
.ag_table_grid_header.reduce_height .ag-theme-alpine .ag-cell{
	line-height: 26px !important;
}

.cashtranscation-alltranscation .ant-radio-button-wrapper{
  padding: 0 16px;
}

.cashtranscation-facevalue .ant-radio-button-wrapper{
  padding: 0 21px;
}

.cashtranscation-entitytype .ant-radio-button-wrapper{
  padding: 0 8px;
}

.cashtranscation-facevalue .ant-form-item-extra{
 font-size: 12px;
 color: rgba(0, 0, 0, 0.35);

}

.fixDataWidth .ant-form-item-control-input-content{
  width: 200px;
}

.live_audit_report form .ant-form-item{margin-bottom: 0!important}
.live_audit_report form label{line-height: 32px;}
.live_audit_report form label span{margin-top: 10px;}
