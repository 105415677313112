.parsed_wrapper {
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	padding: 4px 11px;
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
	font-size: 14px;
}

.view_wrapper {
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 0.6;
	pointer-events: none;
}

.formulize-wrapper .formulize-suffix {
	color: rgba(0, 0, 0, 0.7);
}

.zero_element {
	display: none !important;
}

.formulize-container a.w50.formulize-item {
    width: auto;
}