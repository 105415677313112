.ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
}

.transaction_items_table .ant-table-cell:first-child {
	min-width: 70px;
  }

.transaction_items_table .ant-table-cell:nth-child(19) {
	min-width: 180px;
  }

.transaction_items_table .ant-table-cell:nth-child(20) {
	min-width: 120px;
  }

  // .journal_logs_table .ant-table-cell:first-child {
// 	min-width: 70px;
//  }

