.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc;
}

.row-dragging td {
	padding: 16px;
}

.row-dragging .drag-visible {
	visibility: visible;
}

.grid_wrapper, .ant-page-header.ant-page-header-ghost{
	min-height: 380px;
}

.ant-divider-horizontal {
	margin: 0px 0;
	margin-bottom: 10px;
}

.label_required::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.tradedcenter {
	display: flex;
    justify-content: center;
}

.tablesize_overflow {
	overflow-x: auto;
    overflow-y: hidden;
}

.label_class{
	font-weight: bold;
	font-size: '13px';
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.affix_update_button {
    background: #fff;
    padding: 15px 0;
    position: sticky;
    top: -24px;
    bottom: -24px;
    z-index: 10;
}

.cs_full_height_group {
  .ant-card-body, .ant-card, .ant-form  {
    height: 100%;
  }
}
