.full-width-dragger .ant-form-item-control-input-content > span {
    display: block;
    width: 100%;
}

.cs-table-border {
	border-collapse: collapse;
}

table.cs-table-border, .cs-table-border td, .cs-table-border th {
	border: solid 1px;
    border-color: #babfc7;
    border-color: var(--ag-border-color, #babfc7);
	border-radius: 3px;
	padding: 5px;
	color: #000;
}

.cs-table-border th {
	width: 120px;
}

.cs-table-border td {
	min-width: 100px;
}

.progressBar {
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
	flex-direction: column;
}
.progressBar span {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.progressBar span h3 {
    margin: 0;
    line-height: 10px;
    margin-top: 10px;
}

.draggerList .ant-upload-list.ant-upload-list-text {
    display: flex;
    flex-wrap: wrap;
}

.statusBar span {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.statusBar span h3 {
    margin: 0;
    line-height: 10px;
    margin-top: 10px;
}